import React, { useEffect,useState } from "react";
import { GetFile, upload_file_to_s3 } from "../Common/FileUpload";
import { RotatingLines } from "react-loader-spinner";
import { GrView } from "react-icons/gr";
import { toast } from "react-toastify";

const UploadImage = ({
  item,
  questionTitleImages,
  setQuestionTitleImages,
  indexTitleImage,
}) => {
  const key = Object.keys(item)[0];
  const [imgSrc,setImgSrc]=useState("#");
  const [loading,setLoading]=useState(false);
  const fileName = (uploadedImage) => {
    if(typeof(uploadedImage)=="object"){
      return uploadedImage?.fileName;
    }
    else{
      return uploadedImage?.replace("https://cambri.s3-ap-south-1.amazonaws.com/SAT/","");
    }
  }
  const handleFileChange = async (event) => {
    setLoading(true);
    let public_url;
    try {
      const fileName = event.target.files[0];
      const maxSizeInBytes = 10 * 1024 * 1024; // max 10mb
      if (fileName?.size > maxSizeInBytes) {
        toast.error("Image size should be lessthan 10MB");
        return
      }
      const s3path = await upload_file_to_s3(
        fileName,
      );
      const imageGetResp= await GetFile(s3path?.data?.fileName);
      setImgSrc(imageGetResp);
      public_url = s3path?.data;
      setLoading(false);
      if (public_url) {
        const updatedImages = questionTitleImages.map((img) => {
          if (key === Object.keys(img)[0]) {
            return { [key]: public_url, isView: true, isEdit: false };
          }
          return img;
        });
        setQuestionTitleImages(updatedImages);
      }
    } catch (e) {
      setLoading(false);
      console.log(e, "ERROR");
    }
  };

  useEffect(()=>{
   const getImgUrl=async()=>{
    if(typeof(item[key])=="object"){
      const imageGetResp= await GetFile(item[key]['fileName']);
      setImgSrc(imageGetResp);
    }else{
      setImgSrc(item[key]);
    }
   }
   getImgUrl();
  },[])
  return (
    <div>
      <div className="flex mt-1 mb-3 w-full items-center px-3 mx-4">
        <label>{key}</label>
        <input type="file" onChange={handleFileChange} className="mx-4"/>
        {loading && <div className="flex justify-center items-center">
          <RotatingLines
            visible={true}
            strokeColor="orange"
            height="30"
            width="30"
            strokeWidth="4"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>}
        {item.isView && (
          <div
          className="mx-4 flex"
            onClick={() => {
              let viewImages = questionTitleImages.map((img) => {
                if (key === Object.keys(img)[0]) {
                  return { ...img, isView: true, isEdit: !img.isEdit };
                }
                return img;
              });
              setQuestionTitleImages(viewImages);
            }}
          >
              <span className="mx-2">{fileName(item[key])}</span>
            {item.isEdit ? (
              <button className="border border-[#184ee3] text-[#184ee3]  items-center rounded-full my-2 px-4 py-1 mr-4">
                Close
              </button>
            ) : (
              <GrView className="text-blue-500 text-xl cursor-pointer items-center" />
            )}
          </div>
        )}
      </div>
      <div>{item.isEdit && <img src={imgSrc} alt="cms" />}</div>
    </div>
  );
};

export default UploadImage;
