import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import writingStore from "../../Services/Stores/writingStore";
import { observer } from "mobx-react-lite";
import { GetFile, upload_file_to_s3 } from "../../Components/Common/FileUpload";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { toast } from "react-toastify";
import axios from "axios";

const CreateWritingPart1 = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { partOnePayload, questionsPartOne} = writingStore;
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [imageUrl,setImageUrl] = useState("");
  const selectedSectionLocalStorage = JSON.parse(
    localStorage.getItem("selectedSectionDropDown")
  );
  const storedData = localStorage.getItem("updatedData");
  const updatedData = storedData ? JSON.parse(storedData) : {};
  
  const filedContainer = "flex w-full items-center";
  const labelStyle = "w-2/6 flex justify-start";
  const labelContentStyle =
    "border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white";
  const chartTypeOptions = selectedSectionLocalStorage?.chartType;
  const partOneValuesChange = async (e) => {

    const { name, value, files } = e.target;
    if (files) {
      const file_to_upload = files[0];
      setFileName(file_to_upload.name);
      if (file_to_upload) {
        let s3Data;
        try{
          s3Data = await upload_file_to_s3(file_to_upload);
        }catch(err){
          console.error(err);
          toast.error('Failed to upload the file')
        }
        
        const transformedFiles = [
          {
            fileName: file_to_upload.name,
            name: file_to_upload.name,
            resourceName: '',
            uploadDate: new Date().toISOString(),
            comment: "",
            extension: file_to_upload.name.split(".").pop(),
            s3Document: s3Data?.data,
          },
        ];
        writingStore.setPartOnePayload({
          ...partOnePayload,
          imageUrl: transformedFiles,
        });
      }
    } else {
      let sectionValue = id
        ? updatedData.section
        : selectedSectionLocalStorage.description;
      writingStore.setPartOnePayload({
        ...partOnePayload,
        [name]: value,
        section: sectionValue,
      });
    }
  };

  useEffect(() => {
    if (id) {
      writingStore.setUpdatedData(
        questionsPartOne.find((item) => item?.id === id)
      );
      let removeActive = JSON.parse(JSON.stringify(updatedData));
      writingStore.setPartOnePayload(removeActive);
    }
  }, []);
  const submitData = async () => {
    if (id) {
      writingStore.setPartOnePayload({
        ...partOnePayload,
        createdDate: updatedData.createdDate,
        id: updatedData.id,
        section: updatedData.section,
      });
    }
    try {
      setLoading(true);
      const url = `${
        id
          ? `${serviceUrl}/api/writing/questions/update?questionId=${id}`
          : `${serviceUrl}/api/writing/questions/add`
      }`;
      const response = await axios[id ? "put" : "post"](url, partOnePayload, {
        headers: genericHeaders(),
      });
      if (response.status === 200) {
        setLoading(false);
        toast.success("Saved Successfully");
        navigate("/writing");
        writingStore.setPartOnePayload({});
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something Went Wrong");
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchImageUrl = async () => {
      if (partOnePayload?.imageUrl[0]?.s3Document?.fileName) {
        const url = await GetFile(partOnePayload?.imageUrl[0]?.s3Document?.fileName);
        setImageUrl(url);
      }
    } 
    fetchImageUrl();
  },[partOnePayload])
  console.log('first')
  return (
    <div className="mx-auto w-[80%] min-h-screen">
      <div className="mt-20">
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => navigate("/writing")}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
        </div>
        <div className="flex items-center">
          Writing <IoIosArrowForward /> Section-1 <IoIosArrowForward />
          {id ? "Edit Task" : "Create Task"}
        </div>
        <div className="flex flex-col mt-4 gap-6">
          <div className={filedContainer}>
            <label className={labelStyle}>
              Name<span className="text-red-600 text-xl">*</span>
            </label>
            <input
              type="text"
              className={labelContentStyle}
              placeholder="Name"
              name="name"
              onChange={partOneValuesChange}
              value={partOnePayload?.name}
            />
          </div>
          <div className={filedContainer}>
            <label className={labelStyle}>
              Task<span className="text-red-600 text-xl">*</span>
            </label>
            <textarea
              className={labelContentStyle}
              placeholder="Task"
              name="task"
              rows={7}
              columns={10}
              onChange={partOneValuesChange}
              value={partOnePayload?.task}
            />
          </div>
          <div className={filedContainer}>
            <label className={labelStyle} htmlFor="chartType">
              Chart Type<span className="text-red-600 text-xl">*</span>
            </label>
            <select
              className={labelContentStyle}
              name="chartType"
              value={partOnePayload?.chartType}
              onChange={partOneValuesChange}
            >
              <option value={""}>Select</option>
              {chartTypeOptions?.map((item) => (
                <option key={item?.id} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          {id && partOnePayload?.imageUrl?.length > 0 && (
            <div className={filedContainer}>
              <label className={labelStyle} htmlFor="imageUrl">
                Uploaded Image<span className="text-red-600 text-xl">*</span>
              </label>
              <div className="p-2 w-full justify-start outline-none">
                <img
                  src={imageUrl}
                  alt="image"
                  height={100}
                  className="border border-gray-400 rounded-md p-2 w-40 justify-start outline-none bg-white"
                />
              </div>
            </div>
          )}
          <div className={filedContainer}>
            <label className={labelStyle}>
              Image{!id && <span className="text-red-600 text-xl">*</span>}
            </label>
            <div className="  py-2 px-4  border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white">
              <label
                htmlFor="file-upload"
                className="border border-gray-400 bg-white cursor-pointer my-2 px-4 outline-none "
              >
                {" "}
                Choose File
              </label>
              <span className="ml-2">
                {fileName
                  ? fileName
                  : id
                  ? !Array.isArray(partOnePayload?.imageUrl) ||
                    (Array.isArray(partOnePayload?.imageUrl) &&
                      partOnePayload?.imageUrl[0]?.name)
                  : "No file chosen"}
              </span>
            </div>

            <input
              id="file-upload"
              ref={fileInputRef}
              type="file"
              className="hidden"
              onChange={partOneValuesChange}
            />
          </div>
          <div className={filedContainer}>
            <label className={labelStyle}>
              Image Description<span className="text-red-600 text-xl">*</span>
            </label>
            <textarea
              className={labelContentStyle}
              placeholder="Image Description"
              rows={7}
              columns={10}
              name="imageDescription"
              onChange={partOneValuesChange}
              value={partOnePayload?.imageDescription}
            />
          </div>
        </div>

        <button
          className={`${
            (partOnePayload.imageDescription ?? "") === "" ||
            partOnePayload?.chartType === "" ||
            partOnePayload?.chartType === "Select" ||
            partOnePayload.task === "" ||
            partOnePayload.name === "" ||
            !Array.isArray(partOnePayload?.imageUrl) ||
            (Array.isArray(partOnePayload?.imageUrl) &&
            Object.keys(partOnePayload?.imageUrl[0]?.resourceName === "").length === 0)
              ? "bg-gray-400"
              : "bg-[#E31837]"
          } text-white rounded-md py-2 px-6 mb-4 flex items-center justify-center`}
          onClick={submitData}
          disabled={
            loading ||
            (partOnePayload.imageDescription ?? "") === "" ||
            partOnePayload?.chartType === "" ||
            partOnePayload?.chartType === "Select" ||
            partOnePayload.task === "" ||
            partOnePayload.name === "" ||
            !Array.isArray(partOnePayload?.imageUrl) ||
            (Array.isArray(partOnePayload?.imageUrl) &&
             Object.keys(partOnePayload?.imageUrl[0]?.resourceName === "").length === 0)
          }
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            <span className="ml-1">{!id ? "Save" : "Update"}</span>
          )}
        </button>
      </div>
    </div>
  );
});

export default CreateWritingPart1;
