import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { cloudApplicationUrl, cloudServiceUrl } from "../../utils/apiConfig";
import { jwtDecode } from "jwt-decode";

export const upload_file_to_s3 = async(
  file
) => {

  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if(tokenExpired){
    cloudServiceToken = await loginToCloudService(); 
  }

  try{ 
    const url = `${cloudServiceUrl}s3/upload`;
    
    const formData = new FormData();
    formData.append("file",file);
    const res = await axios.post(url, formData, {
      headers: {
        Authorization : `Bearer ${cloudServiceToken || localStorage.getItem('cloudToken')}`,
        Accept:"*/*",
        'applicationurl' : cloudApplicationUrl,
        'Content-Type': 'multipart/form-data',
      },
    });
    if(res.status===200){
      return res.data;
      //set the response object to any state
    }

  }catch(err){
    if(err instanceof AxiosError){
      const statusCode = err.response?.status;
      if (statusCode === 413) {
        toast.error('File size exceeds more than 10MB');
      }
      else{
        toast.error('File Upload Unsuccessful')
      }
    }
    //if any error is handle here
  }
};

export const GetFile = async(file_name)=>{

  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if(tokenExpired){
    cloudServiceToken = await loginToCloudService(); 
  }
 
  try {
      const url = `${cloudServiceUrl}s3/generate/presigned/url`;
      let payload = {
        'objectKey': file_name
      }
      const response = await axios.post(url,payload,{ headers: {
        Authorization : `Bearer ${cloudServiceToken || localStorage.getItem('cloudToken')}`,
        'applicationurl' : cloudApplicationUrl,
      } });
      if(response.status === 200){
        return response?.data?.data;
      }
      return ''; 
    } catch (error) {
      toast.error('Failed to fetch the file')
      console.error("Error fetching data:", error);
      return '';
    }
}

export const loginToCloudService =async () => {
  try{
    const url = `${cloudServiceUrl}auth/get/token`;
    const payload = {
      "userName": "aWN0c3lzdGVtc0BtYWlsaW5hdG9yLmNvbQ==",
      "password": "MTIzNDU2Nzg5"
    }
    const response = await axios.post(url, payload)
    if(response.status === 200){
      localStorage.setItem('cloudToken',response.data?.data);
      return response.data?.data;
    }
  }catch(err){
    toast.error('cloud Service login failed');
    console.error(err);
  }
}
export const cloudTokenExpiry = () => {
  let cloudToken = localStorage.getItem('cloudToken');
  let decodedToken = null;
  
  if (cloudToken) {
    decodedToken = jwtDecode(cloudToken);
  }else{
    return true;
  }
  
  const currentTime = Math.floor(Date.now() / 1000); 
  const tokenExpired = decodedToken?.exp !== undefined ? decodedToken.exp < currentTime : true;
  return tokenExpired;

}
