import React from "react";
import { FaTrash } from "react-icons/fa";

const Quantity = ({ handleQuantityChange, handleDeleteQuantity, quantity }) => {
  return (
    <div className="flex items-center gap-4 mt-4">
      <input
        type="text"
        name="qunatityName"
        placeholder="Quantity Name"
        value={quantity.qunatityName}
        onChange={(e) => handleQuantityChange(e, quantity.id, e.target.value)}
        className="py-3 w-1/2 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
      />
      <input
        type="text"
        name="qunantityDesc"
        placeholder="Quantity Description"
        value={quantity.qunantityDesc}
        onChange={(e) => handleQuantityChange(e, quantity.id, e.target.value)}
        className="py-3 w-1/2 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
      />
      <FaTrash
        onClick={() => handleDeleteQuantity(quantity.id)}
        className="text-red-600 cursor-pointer ml-4"
      />
    </div>
  );
};

export default Quantity;
