import readingStore from "./Stores/readingStore";
import satReadingStore from "./Stores/satReadingStore";
import writingStore from "./Stores/writingStore";
import analyticalWritingStore from "./Stores/analyticalWritingStore";
import verbalReasoningStore from "./Stores/verbalReasoningStore";
import quantitativeReasoningStore from "./Stores/qunatitativeReasoningStore";

class RootStore {
  constructor() {
    this.readingStore = readingStore;
    this.satReadingStore =satReadingStore;
    this.writingStore = writingStore;
    this.analyticalWritingStore= analyticalWritingStore;
    this.verbalReasoningStore = verbalReasoningStore;
    this.quantitativeReasoningStore = quantitativeReasoningStore;
  }
}

const rootStore = new RootStore();
export default rootStore;
