import React from "react";
import { FaTrash } from "react-icons/fa";
import UploadImage from "../Sat/renderUploadImage";

const MultipleChoiceQuestionWithOneAnswer = ({
  option,
  handleOptionChange,
  handleDeleteOption,
  questionTitleImages,
  setQuestionTitleImages,renderOfImageOptions
}) => {
  return (
  <div>
    <div className="flex items-center gap-4 mt-4">
      <label className="text-[#4E5664] ">{option.answerOption}</label>{" "}
      <input
        type="text"
        name="optionText"
        placeholder="Option Text"
        value={option.answerPlaceHolder}
        onChange={(e) => handleOptionChange(e, option.id, e.target.value)}
        className="py-3 w-1/2 px-4  border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
      />
      <input
        type="radio"
        name="radio"
        className="ml-3"
        checked={option.isCorrectAnswer}
        onChange={(e) => handleOptionChange(e, option.id, e.target.checked)}
      />
      <label className="ml-2">Correct Answer</label>
      <FaTrash
        onClick={() => handleDeleteOption(option.id)}
        className="text-red-600 cursor-pointer ml-4"
      />
    </div>
    {renderOfImageOptions?.map((item, index) => {
        return (
          <>
            <UploadImage
              indexTitleImage={index}
              item={item}
              questionTitleImages={questionTitleImages}
              setQuestionTitleImages={setQuestionTitleImages}
            />
          </>
        );
      })}
  </div>
  );
};

export default MultipleChoiceQuestionWithOneAnswer;
