import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import verbalReasoningStore from "../../Services/Stores/verbalReasoningStore";
import MultipleChoiceQuestionWithMoreThanOneAnswer from "./MultipleChoiceQuestionWithMoreThanOneAnswer";
import UploadImage from "../Sat/renderUploadImage";
import MultipleChoiceQuestionWithOneAnswer from "./MultipleChoiceQuestionWithOneAnswer";
import TextCompletionOption from "./TextCompletionOption";

const VerbalReasoningQuestions = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [questionTitleImages, setQuestionTitleImages] = useState([]);
  const [questionDescriptionImages, setQuestionDescriptionImages] = useState(
    []
  );
  const allQuestionTypes = localStorage.getItem("allQuestionTypes");
  const {
    payload,
    verbalSelectedQuestionType,
    selectedSectionId,
   selectedQuestionId,
    selectedSectionName,
    questionType
  } = verbalReasoningStore;
  let moduleId = localStorage.getItem("moduleId");
  let filedContainer = "flex w-full items-center";
  let labelStyle = "w-2/6 flex justify-start";
  const selectedQuestionTypeName = JSON.parse(localStorage.getItem("selectedQuestionTypeName"));
  let verbalSelectedValue = localStorage.getItem("verbalSelectedSectionName");
  let labelContentStyle =
    "border border-gray-400 rounded-md p-2 w-full justify-start outline-none bg-white";
    const passageInputRef = useRef(null);
    const questionInputRef = useRef(null);
    const questionDescriptionInputRef = useRef(null);
    const addOption = () => {
        const newOption = {
          id: payload?.blanks?.[0]?.answers?.length + 1,
          value: "",
          isActive: true,
          answerOption: `Option ${String.fromCharCode(
            payload?.blanks?.[0]?.answers?.length + 65
          )}`,
          isCorrectAnswer: false,
          correctAnswer: "",
          answerPlaceHolder: "",
        };
        verbalReasoningStore.setPayload({
          ...payload,
          blanks: [
            {
              ...payload.blanks[0],
              answers: [...payload.blanks[0].answers, newOption],
            },
          ],
        });
      };
  const verbalReasoningValuesChange = async (e) => {
    const { name, value } = e.target;
    if (e.target.name === "question") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        question: e.target.value,
      }));
    }
    if (e.target.name === "passage") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        passage: e.target.value,
      }));
    }
    if (e.target.name === "explanation") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        explanation: e.target.value,
      }));
    }
    if (e.target.name === "questionDifficulty") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        questionDifficultyLevel: e.target.value,
      }));
    }
    if (e.target.name === "instruction") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        instructions: e.target.value,
      }));
    }
    if (name === "selectInPassageAnswer") {
      verbalReasoningStore.setPayload((prevQuestion) => ({
        ...prevQuestion,
        selectInPassageAnswer: e.target.value,
      }));
    }

    verbalReasoningStore.setPayload({ ...payload, [name]: value });
  };
  const imagesArray = (questionTitle, existingImages, imageDetails) => {
    const regex = /{#([^}]+)#}/g;
    const resultArray = [];
    let match;

    while ((match = regex.exec(questionTitle)) !== null) {
      const key = match[1];
      const existingImage = existingImages.find(
        (img) => Object.keys(img)[0] === key
      );
      if (existingImage) {
        resultArray.push(existingImage);
      } else {
        const obj = {};
        if (Object.keys(imageDetails).length > 0) {
          obj[key] = imageDetails[key];
          if (imageDetails[key]) {
            obj["isEdit"] = false;
            obj["isView"] = true;
          }
        } else {
          obj[key] = "";
        }
        resultArray.push(obj);
      }
    }

    return resultArray;
  };

  useEffect(() => {
    const questionTitle = payload.question;
    const questionDescription = payload.description;
    const images=payload?.images;

    const resultArrayTitleImages = imagesArray(
      questionTitle,
      questionTitleImages,
      images
    );
    const resultArrayDescriptionImages = imagesArray(
      questionDescription,
      questionDescriptionImages,
      images
    );

    setQuestionTitleImages(resultArrayTitleImages);
    setQuestionDescriptionImages(resultArrayDescriptionImages);
  }, [payload]);


  useEffect(() => {
    verbalReasoningStore.setPayload({
      ...payload,
      sectionId: selectedSectionId || verbalSelectedValue?._id,
      questionTypeId: verbalSelectedQuestionType.id,
      moduleId: moduleId,
    });
  }, []);
  const submitData = async () => {
    try {
      setLoading(true);
      if (!payload.imageDetails) {
        payload.imageDetails = {};
      }

      questionTitleImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = { ...payload.images, ...itm };
      });
      questionDescriptionImages.forEach((itm) => {
        delete itm.isEdit;
        delete itm.isView;
        payload.images = { ...payload.images, ...itm };
      });
      const url = `${
        id
          ? `${serviceUrl}/api/gre/questions/update?questionId=${selectedQuestionId||id}`
          : `${serviceUrl}/api/gre/questions/create`
      }`;
      let temp = JSON.parse(JSON.stringify(payload));
      if (
        verbalSelectedQuestionType?.name === "Text Completion" ||
        selectedQuestionTypeName?.name === "Text Completion"
      ) {
        payload?.blanks.forEach((blank) => {
          delete blank?.id;
          blank?.answers?.forEach((option) => {
            delete option?.id;
          });
        });
      }
      const response = await axios[id ? "put" : "post"](url, payload, {
        headers: genericHeaders(),
      });

      if (response.status === 201 || response.status === 200) {
        verbalReasoningStore.setResetPayload();
        verbalReasoningStore.setVerbalSelectedQuestionType("");
        verbalReasoningStore.setCurrentsectionId("");
        setLoading(false);
        toast.success("Saved Successfully");
        navigate("/verbalReasoning");
        verbalReasoningStore.setSelectedSectionName("");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

    const handleOptionChange = (e, id) => {
        if (e.target.name === "optionText") {
          verbalReasoningStore.setPayload({
            ...payload,
            blanks: [
              {
                ...payload.blanks[0],
                answers: payload.blanks[0].answers.map((option) =>
                  option.id === id
                    ? {
                        ...option,
                        answerPlaceHolder: e.target.value,
                      }
                    : option
                ),
              },
            ],
          });
        } else if (e.target.name === "checkbox") {
            verbalReasoningStore.setPayload({
              ...payload,
              blanks: [
                {
                  ...payload.blanks[0],
                  answers: payload.blanks[0].answers.map((option) => {
                    // Check if the option id matches the clicked checkbox id
                    if (option.id === id) {
                      // Update the isCorrectAnswer property based on checkbox state
                      return {
                        ...option,
                        isCorrectAnswer: e.target.checked,
                      };
                    }
                    // Return the option unchanged if it doesn't match
                    return option;
                  }),
                },
              ],
            });
          }else if (e.target.name === "radio") {
            verbalReasoningStore.setPayload({
              ...payload,
              blanks: [
                {
                  ...payload.blanks[0],
                  answers: payload.blanks[0].answers.map((option) =>
                    option.id === id
                      ? { ...option, isCorrectAnswer: e.target.checked }
                      : { ...option, isCorrectAnswer: false }
                  ),
                },
              ],
            });
          }
  };



  const handleDeleteOption = (id) => {
    const filteredOptions = payload.blanks[0].answers.filter(
      (option) => option.id !== id
    );
    const updatedOptions = filteredOptions.map((option, index) => ({
      ...option,
      answerOption: `Option ${String.fromCharCode(index + 65)}`,
    }));

    verbalReasoningStore.setPayload({
      ...payload,
      blanks: [
        {
          ...payload.blanks[0],
          answers: updatedOptions,
        },
      ],
    });
  };
  const addImage = (name) => {
    let tempQuestion;
    let tempQuestions;
    if (name === "question") {
      tempQuestion =
        payload.question +
        `{#QuestionTitleImage${questionTitleImages.length + 1}#}`;
      tempQuestions = { ...payload, question: tempQuestion };
    }
    if (name === "description") {
      tempQuestion =
        payload.description +
        `{#QuestionDescriptionImage${questionDescriptionImages.length + 1}#}`;
      tempQuestions = { ...payload, description: tempQuestion };
    }
    verbalReasoningStore.setPayload(tempQuestions);
  };
  const addBlank = (key, ref) => {
    const inputElement = ref.current;
    const cursorPosition = inputElement.selectionStart;
    const currentValue = payload[key];
    const newValue =
      currentValue.slice(0, cursorPosition) +
      " ___________ " +
      currentValue.slice(cursorPosition);
    let tempQuestions = { ...payload, [key]: newValue };
    verbalReasoningStore.setPayload(tempQuestions);
  };
  const addBgPlaceHolder = (key, ref) => {
    const inputElement = ref.current;
    const cursorPosition = inputElement.selectionStart;
    const currentValue = payload[key];
    const newValue =
      currentValue.slice(0, cursorPosition) +
      "#bg#bg" +
      currentValue.slice(cursorPosition);
    let tempQuestions = { ...payload, [key]: newValue };
    verbalReasoningStore.setPayload(tempQuestions);
  };
  
  
  const addUnderlineTextPlaceHolder = (key, ref) => {
    const inputElement = ref.current;
    const cursorPosition = inputElement.selectionStart;
    const currentValue = payload[key];
    const underlineText = "<u>Enter Text Here</u>";
    const newValue =
      currentValue.slice(0, cursorPosition) +
      underlineText +
      currentValue.slice(cursorPosition);
    let tempQuestions = { ...payload, [key]: newValue };
    verbalReasoningStore.setPayload(tempQuestions);
  };
  const getQuestionsById = async () => {
    try {
      const url = `${serviceUrl}/api/gre/questions/getbyid?questionId=${selectedQuestionId||id}`;
      const res = await axios.get(url, {
        headers: genericHeaders(),
      });
      const quesType =  res.data.data.questionTypeId === verbalSelectedQuestionType.id;
      const questionType = JSON.parse(allQuestionTypes);
      const questionTypeFilter = questionType.find((item)=>item._id ===res.data.data.questionTypeId );
      localStorage.setItem("selectedQuestionTypeName", JSON.stringify({ id: questionTypeFilter._id, name: questionTypeFilter.name}));
      verbalReasoningStore.setVerbalSelectedQuestionType({
        id: quesType._id,
        name: quesType.name,
      });

      
      if (
        verbalSelectedQuestionType.name === "Text Completion" ||
        selectedQuestionTypeName?.name === "Text Completion"
      ) {
        
        const answersWithIds = res.data.data.blanks.map((blank, i) => ({
          ...blank,
          id: i + 1,
          answers: blank?.answers?.map((answer, ind) => ({
            ...answer,
            id: ind + 1,
          })),
        }));
        verbalReasoningStore.setPayload({
          ...res.data.data,
          blanks: [...answersWithIds],
        });
      } else {
        const answersWithIds = res.data.data.blanks[0].answers.map((answer, i) => ({
          ...answer,
          id: i + 1,
        }));
        verbalReasoningStore.setPayload({
          ...res.data.data,
          blanks: [
            {
              ...payload.blanks[0],
              answers: answersWithIds,
            },
          ],
        });
      }
     


    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (selectedQuestionId || id) {
      getQuestionsById();
    }
  }, [selectedQuestionId,id]);

  const getIsDisabled = () => {

    const checkForIsCorrectAnswer = () => {
      const hasCorrectAnswer = payload?.blanks[0]?.answers?.some(option => option?.isCorrectAnswer)
      return !hasCorrectAnswer;
    }

    const checkOptionIsEmpty = () => {
      const optionIsempty = payload?.blanks[0]?.answers?.every(option => option?.answerPlaceHolder.trim() !== "")
      return !optionIsempty;
    }

    const allBlanksHaveCorrectAnswer = () => {
      const hasCorrectAnswer = payload.blanks.length > 0 && payload.blanks.every((blank) => blank.answers.some(option => option?.isCorrectAnswer))
      return !hasCorrectAnswer;
    }

    const allBlanksHaveFilledPlaceholders = () => {
      const blankOptionIsempty = payload.blanks.length > 0 && payload?.blanks?.every((blank) => blank.answers.length > 0 && blank.answers?.every(option => option?.answerPlaceHolder.trim() !== ""))
      return !blankOptionIsempty;
    }

    if (verbalSelectedQuestionType?.name === "Reading Comprehension - Select-in-Passage" || selectedQuestionTypeName?.name === "Reading Comprehension - Select-in-Passage"){
      return ((payload?.question?.trim() ?? "") === "" || (payload?.selectInPassageAnswer?.trim() ?? "") === "");
    }else if(verbalSelectedQuestionType.name === "Text Completion" || selectedQuestionTypeName?.name === "Text Completion"){
      return (payload?.question?.trim() === "" || allBlanksHaveCorrectAnswer() || allBlanksHaveFilledPlaceholders())
    }else if(verbalSelectedQuestionType?.name !== "Reading Comprehension - Select-in-Passage" || selectedQuestionTypeName?.name !== "Reading Comprehension - Select-in-PassageReading Comprehension - Select-in-Passage"){
      return (payload?.question?.trim() === "" || checkForIsCorrectAnswer() || checkOptionIsEmpty())
    }
  }

      const addBlankOptions = () => {
        let blankOption = {
          id: 0,
          blankName: "",
          answers: [],
        };
        let newData = [...payload.blanks];
        blankOption.id = newData.length + 1;
        newData.push(blankOption);
        verbalReasoningStore.setPayloadBlankOptions(newData);
      };

      const deleteBlankOptions = (ind) => {
        let newData = [...payload.blanks];
        newData.splice(ind, 1);
        newData = newData.map((blank, index) => ({
          ...blank,
          id: index + 1,
        }));
        verbalReasoningStore.setPayloadBlankOptions(newData);
      };

      useEffect(() => {
         if(verbalSelectedQuestionType.name === "Text Completion" ||
          selectedQuestionTypeName?.name === "Text Completion"){
            let newData = [];
            verbalReasoningStore.setPayloadBlankOptions(newData);
          }
      },[])
  return (
    <div className="mx-auto w-[80%] min-h-screen">
      <div className="mt-20">
        <div>
          <button
            className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
            onClick={() => navigate("/verbalReasoning")}
          >
            <IoMdArrowBack /> <span className="ml-1">Back</span>
          </button>
        </div>
        <div className="flex text-lg pt-2">
          {"Verbal Reasoning"}{" "}
          <span className="flex items-center">
            <IoIosArrowForward />{" "}
          </span>
          {selectedSectionName || verbalSelectedValue?.name}{" "}
          <span className="flex items-center">
            <IoIosArrowForward />{" "}
          </span>{" "}
          {verbalSelectedQuestionType.name || selectedQuestionTypeName?.name}
        </div>
        {
          <div className="flex flex-col mt-4 gap-6">
            <div className={filedContainer}>
              <label className={labelStyle}>Instruction</label>
              <textarea
                className={labelContentStyle}
                placeholder="Instruction"
                name="instructions"
                rows={1}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.instructions}
              />
            </div>
            <div className="flex justify-between">
              <label className={labelStyle}>Passage</label>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addBgPlaceHolder("passage", passageInputRef)}
              >
                Add BgPlaceHoder
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() =>
                  addUnderlineTextPlaceHolder("passage", passageInputRef)
                }
              >
                Add underlineText
              </button>
            </div>
            <div className={filedContainer}>
              <textarea
                className={labelContentStyle}
                placeholder="Passage"
                ref={passageInputRef}
                name="passage"
                rows={7}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.passage}
              />
            </div>
            <div className="flex justify-between">
              <label className={labelStyle}>
                Question<span className="text-[#ffa500] text-xl">*</span>
              </label>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addBgPlaceHolder("question", questionInputRef)}
              >
                Add BgPlaceHoder
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() =>
                  addUnderlineTextPlaceHolder("question", questionInputRef)
                }
              >
                Add underlineText
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addImage("question")}
              >
                Add Image
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addBlank("question",questionInputRef)}
              >
                Add Blank
              </button>
            </div>
            <div className={filedContainer}>
              <textarea
                className={labelContentStyle}
                placeholder="Question"
                ref={questionInputRef}
                name="question"
                rows={2}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.question}
              />
            </div>
            {questionTitleImages?.map((item, index) => {
              return (
                <>
                  <UploadImage
                    indexTitleImage={index}
                    item={item}
                    questionTitleImages={questionTitleImages}
                    setQuestionTitleImages={setQuestionTitleImages}
                  />
                </>
              );
            })}
            <div className="flex justify-between">
              <label className="text-black  py-1">Question Description:</label>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addImage("description")}
              >
                Add Image
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] w-40 rounded-full my-2 px-4 py-1 ml-4"
                onClick={() => addBlank("description",questionDescriptionInputRef)}
              >
                Add Blank
              </button>
            </div>
            <div className="flex justify-between">
              <textarea
                className="py-3 my-2 w-full px-4 outline-none  min-h-56 border bg-white border-gray-200 rounded-lg text-sm focus:border-[#ffa500] focus:ring-[#ffa500]disabled:opacity-50 disabled:pointer-events-none"
                rows="2"
                cols="50"
                name="description"
                ref={questionDescriptionInputRef}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload.description}
              ></textarea>
            </div>
            {questionDescriptionImages?.map((item, index) => {
              return (
                <>
                  <UploadImage
                    indexTitleImage={index}
                    item={item}
                    questionTitleImages={questionDescriptionImages}
                    setQuestionTitleImages={setQuestionDescriptionImages}
                  />
                </>
              );
            })}
            <div className="my-2">
              <label className="text-black py-1 mr-2">
                Question Difficulty:
              </label>
              <select
                name="questionDifficultyLevel"
                value={payload.questionDifficultyLevel}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                className="py-2 px-4 bg-white border outline-none focus:border-[#ffa500] focus:ring-[#ffa500] border-gray-200 rounded-lg text-base"
              >
                <option value="">Select Difficulty</option>
                {Array.from({ length: 10 }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <div className={filedContainer}>
              <label className={labelStyle}>Explanation</label>
              <textarea
                className={labelContentStyle}
                placeholder="Explanation"
                name="explanation"
                rows={7}
                columns={10}
                onChange={(e) => {
                  verbalReasoningValuesChange(e);
                }}
                value={payload?.explanation}
              />
            </div>
            {(verbalSelectedQuestionType?.name ===
              "Reading Comprehension - Select-in-Passage" ||
              selectedQuestionTypeName?.name ===
                "Reading Comprehension - Select-in-Passage") && (
              <>
                <label className={labelStyle}>
                  Answer<span className="text-[#ffa500] text-xl">*</span>
                </label>
                <textarea
                  className={labelContentStyle}
                  placeholder="Answer"
                  name="selectInPassageAnswer"
                  rows={7}
                  columns={10}
                  onChange={(e) => {
                    verbalReasoningValuesChange(e);
                  }}
                  value={payload?.selectInPassageAnswer}
                />
              </>
            )}
            {(verbalSelectedQuestionType.name ===
              "Reading Comprehension - MCQ's with more than one Answer" ||
              selectedQuestionTypeName?.name ===
                "Reading Comprehension - MCQ's with more than one Answer") && (
              <div>
                <button
                  onClick={addOption}
                  className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 mr-4 w-40"
                >
                  Add Option
                </button>
                {payload?.blanks?.[0]?.answers?.length > 0 &&
                  payload.blanks[0].answers.map((option) => (
                    <MultipleChoiceQuestionWithMoreThanOneAnswer
                      key={option.id}
                      option={option}
                      handleOptionChange={(e) =>
                        handleOptionChange(e, option.id, e.target.value)
                      }
                      handleDeleteOption={() => handleDeleteOption(option.id)}
                    />
                  ))}
              </div>
            )}
            {(verbalSelectedQuestionType.name === "Sentence Equivalence" ||
              selectedQuestionTypeName?.name === "Sentence Equivalence") && (
              <div>
                <button
                  onClick={addOption}
                  className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 mr-4 w-40"
                >
                  Add Option
                </button>
                {payload?.blanks?.[0]?.answers?.length > 0 &&
                  payload.blanks[0].answers.map((option) => (
                    <MultipleChoiceQuestionWithMoreThanOneAnswer
                      key={option.id}
                      option={option}
                      handleOptionChange={(e) =>
                        handleOptionChange(e, option.id, e.target.value)
                      }
                      handleDeleteOption={() => handleDeleteOption(option.id)}
                    />
                  ))}
              </div>
            )}
            {(verbalSelectedQuestionType.name ===
              "Reading Comprehension - MCQ's With single Answer" ||
              selectedQuestionTypeName?.name ===
                "Reading Comprehension - MCQ's With single Answer") && (
              <div>
                <button
                  onClick={addOption}
                  className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 mr-4 w-40"
                >
                  Add Option
                </button>
                {payload?.blanks?.[0]?.answers?.length > 0 &&
                  payload.blanks[0].answers.map((option) => (
                    <MultipleChoiceQuestionWithOneAnswer
                      key={option.id}
                      option={option}
                      handleOptionChange={(e) =>
                        handleOptionChange(e, option.id, e.target.value)
                      }
                      handleDeleteOption={() => handleDeleteOption(option.id)}
                    />
                  ))}
              </div>
            )}
            {(verbalSelectedQuestionType.name === "Text Completion" ||
              selectedQuestionTypeName?.name === "Text Completion") && (
              <div>
                <button
                  onClick={addBlankOptions}
                  className="border border-[#ffa500] text-[#ffa500] rounded-full my-2 px-4 py-1 mr-4 w-40"
                >
                  Add Blank Option
                </button>
                <div className="mt-5 space-y-5">
                  {payload.blanks.length > 0 &&
                    payload.blanks.map((blankOption, index) => (
                      <TextCompletionOption
                        key={blankOption.id}
                        blankIndex={index}
                        blankOption={blankOption}
                        handleDeleteBlankOption={deleteBlankOptions}
                        setPayloadBlankOptions={
                          verbalReasoningStore.setPayloadBlankOptions
                        }
                        blanksData={payload.blanks}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        }

        <button
          className={`${
            getIsDisabled() ? "bg-gray-400" : "bg-[#ffa500]"
          } text-white rounded-md py-2 px-6 mb-4 mt-4`}
          onClick={submitData}
          disabled={getIsDisabled()}
        >
          {loading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          ) : (
            <span className="ml-1 ">{!id ? "Save" : "Update"}</span>
          )}
        </button>
      </div>
    </div>
  );
});

export default VerbalReasoningQuestions;
