import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Modal from "../Common/Modal";
import analyticalWritingStore from "../../Services/Stores/analyticalWritingStore";
import verbalReasoningStore from "../../Services/Stores/verbalReasoningStore";
import { toJS } from "mobx";
import quantitativeReasoningStore from "../../Services/Stores/qunatitativeReasoningStore";


const GreQuestionTypeModal = observer(
  ({ isOpen, onClose,  onQuestionTypeChange, data }) => {
    let moduleName = localStorage.getItem("Modulename")
    const {selectedQuestionType} = analyticalWritingStore;
    const {verbalSelectedQuestionType} = verbalReasoningStore;
    const {selectedQuantitativeQuestionType} = quantitativeReasoningStore;

    const handleSelectChange = (e) => {
      const selectedId = e.target.options[e.target.selectedIndex].dataset.id;
      const selectedName = e.target.value;
      analyticalWritingStore.setSelectedQuestionType({ id: selectedId, name: selectedName });
      verbalReasoningStore.setVerbalSelectedQuestionType({ id: selectedId, name: selectedName });
      quantitativeReasoningStore.setSelectedQuantitativeQuestionType({ id: selectedId, name: selectedName });
      localStorage.setItem("selectedQuestionTypeName", JSON.stringify({ id: selectedId, name: selectedName }));
      analyticalWritingStore.savePersistedData();
    };
    const isOkButtonDisabled = (Object.entries(selectedQuantitativeQuestionType).length === 0 || selectedQuestionType === "" || selectedQuestionType.name === "" )|| (verbalSelectedQuestionType === ""|| verbalSelectedQuestionType.name === "");

    return (
      <div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold mb-8">Select Question Type</h1>
            <select
              className="border border-[#E3E5E9] py-4 px-2 rounded-lg focus:outline-none"
              onChange={handleSelectChange}
              value={`${moduleName === "Analytical Writing" ? (
                selectedQuestionType ? selectedQuestionType.name : ""
              ) : moduleName === "Verbal Reasoning" ? (
                verbalSelectedQuestionType ? verbalSelectedQuestionType.name : ""
              ) : selectedQuantitativeQuestionType ? selectedQuantitativeQuestionType.name : ""}`}
            >
              <option value="">Select</option>
              {data &&
                data?.length > 0 &&
                data?.map((item) => (
                  <option key={item._id} value={item.name} data-id={item._id}>
                    {item.name}
                  </option>
                ))}
            </select>
            <div className="flex justify-between mt-8">
              <button
                className={`${isOkButtonDisabled ?"bg-gray-400":"bg-[#ffa500] cursor-pointer" } text-white rounded-3xl px-4 py-1 my-4`}
                onClick={onQuestionTypeChange}
                disabled={isOkButtonDisabled} 
              >
                Ok
              </button>
              <button
                className="border border-[#ffa500] text-[#ffa500] rounded-3xl px-4 py-1 my-4"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default GreQuestionTypeModal;
